<template>
  <div class="promobanner-n-pay-now-layout">
    <div class="cpp-container">
      <div class="banner">
        <img :src="promoBanner" alt="promo banner">
      </div>
      <div class="payment-layout">
        <span class="merchant-title">{{ pageTitle }}</span>
        <span class="merchant-des">{{ pageTagline }}</span>
        <v-btn class="pay-now-btn" @click="$emit('payNowClicked')">Pay Now</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PromoBanner',
  props: {
    promoBanner: String,
    pageTitle: String,
    pageTagline: String,
  }
}
</script>

<style scoped>
.promobanner-n-pay-now-layout {
  background-color: #f4f4f4;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.cpp-container {
  max-width: 1920px;
  min-height: 320px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
}

.banner {
  width: 60%;
  padding: 25px;
}

.banner img {
  height: 100%;
  width: 100%;
  border-radius: 8px;
}

.payment-layout {
  width: 40%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pay-now-btn {
  font-weight: 550 !important;
  height: 60px !important;
  width: 200px !important;
  letter-spacing: normal !important;
  font-size: 24px !important;
  border-radius: 7px;
  color: #FFFFFF !important;
  background-image: linear-gradient(275deg, #e2136e, rgba(226, 19, 110, 0.75));
  box-shadow: 0 2px 20px 0 grey;
}

.pay-now-btn:hover {
  -moz-box-shadow: 0 10px 50px 2px grey;
  -webkit-box-shadow: 0 10px 50px 2px grey;
  box-shadow: 0 10px 50px 2px grey;
}

.merchant-title {
  font-size: 44px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #464646;
}

.merchant-des {
  margin-bottom: 26px;
  margin-top: 15px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: center;
  color: #464646;
}

@media screen and (max-width: 768px) {
  .cpp-container {
    min-height: auto;
    flex-direction: column;
  }

  .banner {
    padding: 0;
    width: 100%;
  }

  .banner img {
    border-radius: 0;
  }

  .payment-layout {
    padding: 10px;
    width: 100%;
  }

  .merchant-title {
    font-size: 20px;
  }

  .merchant-des {
    font-size: 16px;
  }

  .pay-now-btn {
    font-size: 14px !important;
    height: 35px !important;
    width: 110px !important;
    border-radius: 5px !important;
  }
}
</style>
<template>
  <div class="merchant-information">
    <div class="cpp-container">
      <div class="merchant-details">
        <div class="detail-container">
          <div class="detail-title">About Us</div>
          <div class="detail-info"
               v-html="pageDetails.about"></div>
        </div>
        <div class="detail-container">
          <div class="detail-title">Services</div>
          <div class="detail-info"
               v-html="pageDetails.serviceDetails"></div>
        </div>
      </div>
      <div class="merchant-contact">
        <div class="merchant-logo">
          <img v-if="isRemoteLogo(pageDetails.logo)" :src="pageDetails.logo" alt="merchant logo">
          <img v-else :src="'data:image/png;base64,' + pageDetails.logo" alt="merchant logo">
        </div>
        <div class="merchant-contact-title">Contact Us</div>
        <div class="contact-items">
          <div class="contact-item" v-if="pageDetails.contactEmail">
            <v-icon class="icon">mdi-email-newsletter</v-icon>
            <a target="_blank" :href="`mailto:${pageDetails.contactEmail}`" class="contact-text">
              {{ pageDetails.contactEmail }}
            </a>
          </div>
          <div class="contact-item" v-if="pageDetails.contactWebsite">
            <v-icon class="icon">mdi-web</v-icon>
            <a target="_blank" :href="pageDetails.contactWebsite" class="contact-text hyper-link-style">
              {{ pageDetails.contactWebsite }}
            </a>
          </div>
          <div class="contact-item" v-if="pageDetails.contactFacebook">
            <v-icon class="icon">mdi-facebook</v-icon>
            <a target="_blank" :href="pageDetails.contactFacebook" class="contact-text hyper-link-style">
              {{ pageDetails.contactFacebook }}
            </a>
          </div>
          <div class="contact-item" v-if="pageDetails.contactPhone">
            <v-icon class="icon">mdi-phone-in-talk</v-icon>
            <a target="_blank" :href="`tel:${pageDetails.contactPhone}`" class="contact-text">
              {{ pageDetails.contactPhone | displayContactNumber }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {isRemoteLogo} from "cpp-js-util/CppUtil";

export default {
  name: 'MerchantInformation',
  props: {
    pageDetails: Object,
  },
  filters: {
    displayContactNumber(contactNumber) {
      if (!contactNumber) return

      let replacedNumber = '';

      if (contactNumber.startsWith('+880') || contactNumber.startsWith('880')) {
        replacedNumber = contactNumber.replace('+', '');
        replacedNumber = replacedNumber.replace('880', '0')
      } else {
        replacedNumber = contactNumber.replace('0', '880');
      }

      let numberArray = replacedNumber.split('');

      numberArray.splice(3, 0, ' ')
      numberArray.splice(8, 0, ' ')
      numberArray.splice(12, 0, ' ')

      return numberArray.join('')
    }
  },
  methods: {
    isRemoteLogo,
  }
}
</script>

<style scoped>
.merchant-information {
  background-color: #FFFFFF;
  color: #464646;
}

.cpp-container {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
}

.hyper-link-style {
  text-decoration: underline !important;
  text-decoration-color: #363636;
}

.merchant-information, .merchant-details, .merchant-contact {
  padding: 24px;
}

.merchant-information .merchant-details {
  width: 70%;
  border-right: solid 1px rgba(0, 0, 0, 0.1);
}

.merchant-information .merchant-contact {
  width: 30%;
  padding: 60px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.merchant-details .detail-container {
  padding: 20px;
}

.detail-container .detail-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
}

.detail-container .detail-info {
  font-size: 18px;
}

.merchant-contact .merchant-logo img {
  height: 90px;
  width: 180px;
}

.merchant-contact .merchant-contact-title {
  font-size: 28px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
}

.contact-items {
  display: flex;
  flex-direction: column;
}

.contact-items .contact-item {
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.contact-items .contact-item .contact-text {
  font-size: 18px;
  word-break: break-all;
  color: #464646;
  margin-left: 8px;
  text-decoration: none;
  text-align: left;
}

.contact-items .contact-item .icon {
  color: #e2136e;
}

@media screen and (max-width: 768px) {
  .merchant-information {
    padding: 5px;
  }

  .cpp-container {
    flex-direction: column;
  }

  .merchant-information .detail-container {
    padding: 0;
    margin-bottom: 20px;
  }

  .detail-container .detail-title {
    font-size: 18px;
  }

  .detail-container .detail-info {
    font-size: 16px;
  }

  .merchant-information .merchant-contact {
    width: 100%;
    padding: 20px 5px;
  }

  .merchant-contact .merchant-contact-title {
    font-size: 18px;
  }

  .merchant-information .merchant-details {
    padding: 10px;
    border-right: 0;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    width: 100%;
  }

  .contact-text {
    font-size: 16px !important;
  }

  .merchant-contact .merchant-logo img {
    height: 60px;
    width: 120px;
  }
}
</style>

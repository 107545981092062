<template>
  <div>
    <PromoBanner :promo-banner="promoBanner"
                 :page-title="pageInfo.title"
                 :page-tagline="pageInfo.tagline"
                 @payNowClicked="goToPaymentPage"
    />
    <MerchantInformation :page-details="pageInfo"/>
  </div>
</template>

<script>
import PromoBanner from "cpp-js-util/components/common/PromoBanner";
import MerchantInformation from "cpp-js-util/components/common/MerchantInformation";
export default {
  name: "Home",
  components: {MerchantInformation, PromoBanner},
  computed: {
    pageInfo() {
      return this.$store.getters.getPageInfo;
    },
    promoBanner() {
      return this.$store.getters.getPromotionalBanner;
    }
  },
  methods: {
    goToPaymentPage() {
      this.$router.push({name: 'customer-payment-page'})
    }
  }
}
</script>

<style>

</style>
